import { useEffect, useReducer } from "react";
import styled from "styled-components";

import { VoteStatus } from "types/voteEnum";

import OptionsWrapper from "./OptionsWrapper";
import ActionSection from "components/vote/Quizzbox/common/ActionSection";
import TitleHeader from "../../common/TitleHeader";

type Props = {
    onValidate: (answer: string | Array<string>) => Promise<{
        [key: string]: any;
    }>;
    minNumberOfAnswers?: number;
    maxNumberOfAnswers?: number;
    options: Array<{
        id: string;
        text: string;
    }>;
    title: string;
};

const initialState: VoteState = {
    voteStatus: VoteStatus.Idle,
    answers: [],
};

type VoteState = {
    voteStatus: VoteStatus;
    answers: Array<string>;
};

export type Action =
    | { type: "addAnswer"; answer: string }
    | { type: "deleteAnswer"; answer: string }
    | { type: "replaceAnswer"; answer: string }
    | { type: "validateVote" }
    | { type: "errorVote" }
    | { type: "loadingVote" }
    | { type: "resetVote" };

const ChoiceQuestion = ({
    onValidate,
    minNumberOfAnswers = 1,
    maxNumberOfAnswers = 1,
    options,
    title,
}: Props) => {
    function reducer(state: VoteState, action: Action): VoteState {
        switch (action.type) {
            case "addAnswer": {
                const newAnswers = [...state.answers, action.answer];
                const newVoteStatus =
                    newAnswers.length >= minNumberOfAnswers
                        ? VoteStatus.PendingValidation
                        : VoteStatus.Idle;

                return {
                    voteStatus: newVoteStatus,
                    answers: newAnswers,
                };
            }
            case "replaceAnswer": {
                return {
                    voteStatus: VoteStatus.PendingValidation,
                    answers: [action.answer],
                };
            }
            case "deleteAnswer": {
                const newAnswers = [
                    ...state.answers.filter(
                        (answer) => answer !== action.answer
                    ),
                ];
                const newVoteStatus =
                    newAnswers.length < minNumberOfAnswers
                        ? VoteStatus.Idle
                        : VoteStatus.PendingValidation;
                return {
                    voteStatus: newVoteStatus,
                    answers: newAnswers,
                };
            }
            case "validateVote": {
                return {
                    ...state,
                    voteStatus: VoteStatus.Success,
                };
            }
            case "errorVote": {
                return {
                    ...state,
                    voteStatus: VoteStatus.Error,
                };
            }
            case "loadingVote": {
                return {
                    ...state,
                    voteStatus: VoteStatus.Loading,
                };
            }
            case "resetVote": {
                return {
                    voteStatus: VoteStatus.Idle,
                    answers: [],
                };
            }
        }
    }

    const [vote, dispatchVoteAction] = useReducer(reducer, initialState);

    useEffect(() => {
        // This check is performed to handle cases where a user reconnects
        // via a mobile socket without being notified of the closure of the
        // previous vote. We add question as dependency in order
        // to determine if the vote state needs to be updated. If the titles
        // has changed, it means the question has changed, and we need to
        // reset reducer state so as to reset voting state
        dispatchVoteAction({ type: "resetVote" });
    }, [title]);

    const handleValidate = () => {
        const answersConvert = vote.answers.join("");
        dispatchVoteAction({
            type: "loadingVote",
        });
        onValidate(answersConvert)
            .then(() => {
                dispatchVoteAction({
                    type: "validateVote",
                });
            })
            .catch(() => {
                dispatchVoteAction({
                    type: "errorVote",
                });
            });
    };

    const handleReset = () => {
        dispatchVoteAction({
            type: "resetVote",
        });
    };

    return (
        <WrapperVote>
            <TitleHeader title={title} />
            <OptionsWrapper
                maxNumberOfAnswers={maxNumberOfAnswers}
                options={options}
                answers={vote.answers}
                voteStatus={vote.voteStatus}
                dispatchVoteAction={dispatchVoteAction}
            />
            <ActionSection
                voteStatus={vote.voteStatus}
                handleValidate={handleValidate}
                handleReset={handleReset}
            />
        </WrapperVote>
    );
};

const WrapperVote = styled.div`
    @media (max-width: 768px) {
        max-width: 350px;
    }
    > span {
        font-size: 1.3rem;
    }
`;

export default ChoiceQuestion;
