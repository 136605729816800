import styled from "styled-components";
import { useTranslation } from "react-i18next";

import { VoteStatus } from "types/voteEnum";

import ValidateButton from "./Buttons/ValidateButton";

type Props = {
    voteStatus: string;
    handleValidate: () => void;
    handleReset?: () => void;
};

const ActionSection = ({ voteStatus, handleValidate, handleReset }: Props) => {
    const i18n = useTranslation();

    const renderMessage = () => {
        switch (voteStatus) {
            case VoteStatus.PendingValidation:
                return <p>{i18n.t("answerNotValidated.quizzbox")}</p>;
            case VoteStatus.Success:
                return <p>{i18n.t("sentSucceded.quizzbox")}</p>;
            case VoteStatus.Loading:
                return <p>{i18n.t("loading")}</p>;
            case VoteStatus.Error:
                return <p>{i18n.t("errorUnknown")}</p>;
            default:
                return <p style={{ height: "16px" }}></p>;
        }
    };

    return (
        <Wrapper>
            {VoteStatus.Idle === voteStatus ? (
                <div style={{ height: "30px" }}></div>
            ) : (
                <Message
                    color={VoteStatus.Success === voteStatus ? "green" : "red"}
                >
                    <div>{renderMessage()}</div>
                </Message>
            )}
            <ValidateButton
                isDisabled={VoteStatus.Idle === voteStatus}
                onClick={
                    handleReset
                        ? VoteStatus.PendingValidation === voteStatus
                            ? handleValidate
                            : handleReset
                        : handleValidate
                }
                text={
                    VoteStatus.Success === voteStatus ? (
                        <span>{i18n.t("changeAnswer.quizzbox")}</span>
                    ) : (
                        <span>{i18n.t("validateAnswer.quizzbox")}</span>
                    )
                }
                color={
                    VoteStatus.Success === voteStatus ? "#808080" : "#60b860"
                }
            />
        </Wrapper>
    );
};

const Wrapper = styled.div`
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 14px;
    margin-top: 1.75rem;
    margin-bottom: 1.75rem;
`;

const Message = styled.div`
    color: ${(props) => props.color};
    width: 100%;
    text-align: center;
`;

export default ActionSection;
