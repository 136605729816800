import styled from "styled-components";
import { EvaluationProps, RatingWrapper } from "./GroupedEvaluation";
import Rating from "@mui/material/Rating";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import FavoriteIcon from "@mui/icons-material/Favorite";

const HeartsEvaluation = (props: EvaluationProps) => {
    return (
        <RatingWrapper>
            <p>{props.subTitle}</p>
            <StyledRatingHeart
                emptyIcon={<FavoriteBorderIcon fontSize="inherit" />}
                icon={<FavoriteIcon />}
                value={Number(props.answers[props.index]?.answer)}
                max={props.maxValue}
                precision={props.useDecimal ? 0.5 : 1}
                onChange={(e, newValue) =>
                    props.onRateChange(e, newValue, props.id, props.index)
                }
            />
        </RatingWrapper>
    );
};

const StyledRatingHeart = styled(Rating)({
    "& .MuiRating-iconFilled": {
        color: "#ff6d75",
    },
    "& .MuiRating-iconHover": {
        color: "#ff3d47",
    },
});

export default HeartsEvaluation;
