import React, { ChangeEvent, SetStateAction, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import TitleHeader from "../../../common/TitleHeader";
import { Answer } from "./GroupedEvaluation";

type Props = {
    id: number;
    index: number;
    useDecimal: boolean;
    minValue: number;
    maxValue: number;
    answers: Answer[];
    setAnswers: React.Dispatch<SetStateAction<Answer[]>>;
    reset: boolean;
    subTitle: string;
};

const InputEvaluation = ({
    id,
    useDecimal,
    minValue,
    maxValue,
    answers,
    setAnswers,
    index,
    reset,
    subTitle,
}: Props) => {
    const [inputValue, setInputValue] = useState<string>("");

    const i18n = useTranslation();

    useEffect(() => {
        if (reset) {
            setInputValue("");
        }
    }, [reset]);

    const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        const { value } = e.currentTarget;

        if (Number(value) > maxValue) {
            setInputValue(maxValue.toString());
        } else if (Number(value) < minValue) {
            setInputValue(minValue.toString());
        } else {
            setInputValue(value);
        }
    };

    const handleBlur = () => {
        const newAnswers = [...answers];

        newAnswers[index] = {
            answer: !Number.isNaN(Number(inputValue))
                ? Number(inputValue)
                : null,
            weight: 1,
            questionId: id.toString(),
        };

        setAnswers(newAnswers);
    };

    return (
        <Wrapper>
            <TitleHeader title={subTitle} />
            <StyledInput
                value={inputValue}
                onChange={handleInputChange}
                onBlur={handleBlur}
                type={"number"}
                placeholder={i18n.t("enterNumber")}
                min={minValue ? minValue : undefined}
                max={maxValue ? maxValue : undefined}
                step={useDecimal ? 0.1 : undefined}
            />
        </Wrapper>
    );
};

const Wrapper = styled.div`
    text-align: center;
    margin: 1.5rem 0;
`;

const StyledInput = styled.input`
    margin: auto;
    height: 35px;
    padding: 6px 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 15px;
    width: 100%;
    box-sizing: border-box;
    &[disabled] {
        background: #e8e8e8;
        opacity: 0.7;
        cursor: not-allowed;
    }
    :focus-visible {
        outline: solid 1px #010203;
    }
`;
export default InputEvaluation;
