import { getSocket } from "components/socket";
import { EVENT } from "types/socketEventsType";

const emitAsync = (args: {
    votingDeviceId: string;
    responseFormatVersion: number;
    answers: { questionId: number; answer: string; weight: number }[];
}) => {
    return new Promise<{ [key: string]: any }>((resolve, reject) => {
        getSocket().emit(EVENT.ANSWER, args, (res: any) => {
            if ("SUCCESS" === res.status) {
                resolve(res.data);
            } else {
                reject({ code: res.code, message: res.message });
            }
        });
    });
};

export default emitAsync;
