import { Dispatch, useEffect, useReducer } from "react";
import { QuestionType } from "types/dataVoteType";

type Answer = {
    id: string;
    weight: number;
    disableOtherPropositionsIfSelected: boolean;
};

export enum VoteStatus {
    PendingValidation = "pendingValidation",
    Idle = "idle",
    Incomplete = "incomplete",
    Loading = "loading",
    Success = "success",
    Error = "error",
    NotAllowed = "notAllowed",
}

export type VoteState = {
    voterId: string;
    voteStatus: VoteStatus;
    answers: Array<Answer>;
};

type State = Array<VoteState>;

export type Person = {
    displayName: string;
    numTelecoEncrypted: string;
    weight: number;
};

export type VoteStateAction =
    | {
          type: "updateWeightVote";
          id: string;
          weight: number;
          voterId: string;
      }
    | {
          type: "replaceVoteForEveryParticipant";
          id: string;
      }
    | { type: "sendAnswersRequest"; voterId: string }
    | { type: "sendAnswersSuccess"; voterId: string }
    | { type: "sendAnswersError"; voterId: string }
    | { type: "resetVote" };

function useVoteState(
    question: QuestionType,
    proxies: Array<Person>,
    user: Person
) {
    function reducer(state: State, action: VoteStateAction): State {
        switch (action.type) {
            case "updateWeightVote": {
                return [
                    ...state.map((vote) => {
                        if (vote.voterId !== action.voterId) {
                            return vote;
                        }

                        const voter = [user, ...proxies].find(
                            (person) =>
                                person.numTelecoEncrypted === vote.voterId
                        ) as Person;

                        const shouldResetOtherProposalsWeight =
                            action.weight === voter.weight;

                        const newAnswers = vote.answers.map((answer) => {
                            if (answer.id === action.id) {
                                return { ...answer, weight: action.weight };
                            }

                            return shouldResetOtherProposalsWeight
                                ? { ...answer, weight: 0 }
                                : answer;
                        });

                        const distributedWeight = newAnswers.reduce(
                            (previousValue, currentValue) =>
                                previousValue + currentValue.weight,
                            0
                        );

                        const getNewVoteStatus = () => {
                            if (distributedWeight >= voter.weight) {
                                return VoteStatus.PendingValidation;
                            } else if (0 === distributedWeight) {
                                return VoteStatus.Idle;
                            } else {
                                return VoteStatus.Incomplete;
                            }
                        };
                        return {
                            voterId: vote.voterId,
                            voteStatus: getNewVoteStatus(),
                            answers: newAnswers,
                        };
                    }),
                ];
            }
            case "replaceVoteForEveryParticipant": {
                return [
                    ...state.map((vote) => {
                        const voter = [user, ...proxies].find(
                            (person) =>
                                person.numTelecoEncrypted === vote.voterId
                        ) as Person;

                        const newAnswers = vote.answers.map((answer) => {
                            if (answer.id === action.id) {
                                return {
                                    ...answer,
                                    weight: voter.weight,
                                };
                            }
                            return { ...answer, weight: 0 };
                        });

                        return {
                            ...vote,
                            voteStatus: VoteStatus.PendingValidation,
                            answers: newAnswers,
                        };
                    }),
                ];
            }
            case "sendAnswersRequest": {
                return [
                    ...state.map((vote) => {
                        if (vote.voterId !== action.voterId) {
                            return vote;
                        }

                        return {
                            ...vote,
                            voteStatus: VoteStatus.Loading,
                        };
                    }),
                ];
            }
            case "sendAnswersSuccess": {
                return [
                    ...state.map((vote) => {
                        if (vote.voterId !== action.voterId) {
                            return vote;
                        }

                        return {
                            ...vote,
                            voteStatus: VoteStatus.Success,
                        };
                    }),
                ];
            }
            case "sendAnswersError": {
                return [
                    ...state.map((vote) => {
                        if (vote.voterId !== action.voterId) {
                            return vote;
                        }

                        return {
                            ...vote,
                            voteStatus: VoteStatus.Error,
                        };
                    }),
                ];
            }
            case "resetVote": {
                return [
                    ...state.map((vote) => {
                        const voter = [user, ...proxies].find(
                            (person) =>
                                person.numTelecoEncrypted === vote.voterId
                        ) as Person;

                        return {
                            voterId: vote.voterId,
                            answers: createInitialAnswers(
                                question.propositions
                            ),
                            voteStatus: createInitialVoteStatus(voter.weight),
                        };
                    }),
                ];
            }
        }
    }

    const initialState: State = [user, ...proxies].map((person) => ({
        voterId: person.numTelecoEncrypted,
        voteStatus: createInitialVoteStatus(person.weight),
        answers: createInitialAnswers(question.propositions),
    }));

    const [state, dispatch] = useReducer(reducer, initialState);

    useEffect(() => {
        // This check is performed to handle cases where a user reconnects
        // via a mobile socket without being notified of the closure of the
        // previous vote. We add question as dependency in order
        // to determine if the vote state needs to be updated. If the titles
        // has changed, it means the question has changed, and we need to
        // reset reducer state so as to reset voting state
        dispatch({ type: "resetVote" });
    }, [question]);

    return [state, dispatch] as [State, Dispatch<VoteStateAction>];
}

type Proposal = {
    id: string;
    disableOtherPropositionsIfSelected: boolean;
};

function createInitialVoteStatus(personWeight: number): VoteStatus {
    if (0 === personWeight) {
        return VoteStatus.NotAllowed;
    } else {
        return VoteStatus.Idle;
    }
}

function createInitialAnswers(proposals: Array<Proposal>): Array<Answer> {
    return proposals.map((proposal) => ({
        id: proposal.id,
        weight: 0,
        disableOtherPropositionsIfSelected:
            proposal.disableOtherPropositionsIfSelected,
    }));
}

export default useVoteState;
