import styled from "styled-components";
import { BaseOptionButton } from "./BaseOptionButton";
import { CSS_VARIABLES } from "enums/cssVariables";

type Props = {
    text: string;
    isDisabled: boolean;
    onClick: () => void;
};

const ValidateButton = ({ isDisabled, text, onClick }: Props) => {
    return (
        <StyledButton
            disabled={isDisabled}
            onClick={onClick}
            data-test-id="btn-validate-votebox"
        >
            {text}
        </StyledButton>
    );
};

const StyledButton = styled(BaseOptionButton)`
    background: var(${CSS_VARIABLES.PRIMARY_BUTTON_BACKGROUND_COLOR});
    color: var(${CSS_VARIABLES.PRIMARY_BUTTON_TEXT_COLOR});
    border: 1px solid var(${CSS_VARIABLES.PRIMARY_BUTTON_BACKGROUND_COLOR});
    margin-bottom: 0;
    &[disabled] {
        opacity: 0.65;
        cursor: not-allowed;
    }
`;

export default ValidateButton;
