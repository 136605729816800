import { ReactElement } from "react";
import styled from "styled-components";

import { BaseProposal } from "./BaseProposal";

type Props = {
    isDisabled: boolean;
    color: string;
    text: ReactElement | string;
    onClick: () => void;
};

const ValidateButton = ({ isDisabled, color, text, onClick }: Props) => {
    return (
        <StyledButton
            data-test-id="quiz-validate-button"
            disabled={isDisabled}
            onClick={onClick}
            color={color}
        >
            {text}
        </StyledButton>
    );
};

const StyledButton = styled(BaseProposal)`
    color: #fff;
    background-color: ${(props) => props.color};
    border: 1px solid ${(props) => props.color};
    margin-bottom: 0;
    min-height: 2.5rem;
    :focus-visible {
        outline: solid 2px #010203;
    }
    &[disabled] {
        opacity: 0.65;
        cursor: not-allowed;
    }
`;

export default ValidateButton;
