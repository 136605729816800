import styled from "styled-components";
import {
    EvaluationProps,
    IconContainer,
    RatingWrapper,
} from "./GroupedEvaluation";
import Rating from "@mui/material/Rating";

const SmilesEvaluation = (props: EvaluationProps) => {
    return (
        <RatingWrapper>
            <p>{props.subTitle}</p>
            <StyledRatingSmiley
                value={Number(props.answers[props.index]?.answer)}
                max={props.maxValue}
                precision={props.useDecimal ? 0.5 : 1}
                IconContainerComponent={IconContainer}
                onChange={(e, newValue) =>
                    props.onRateChange(e, newValue, props.id, props.index)
                }
            />
        </RatingWrapper>
    );
};

const StyledRatingSmiley = styled(Rating)(({ theme }) => ({
    "& .MuiRating-iconEmpty .MuiSvgIcon-root": {
        color: "darkgrey",
    },
}));

export default SmilesEvaluation;
