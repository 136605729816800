import { LS_AUTH_TOKEN_KEY } from "constants/constants";
import { getUrl } from "services/urlService";
import omit from "lodash.omit";

const { fetch: originalFetch } = window;
const fetch = async (...args: any): Promise<any> => {
    const JWT_EXPIRED = "JWT_EXPIRED";
    const JWT_USER_UNKNOWN = "JWT_USER_UNKNOWN";

    let [resource, config] = args;

    let response = await originalFetch(resource, config);
    if (!response.ok && 401 === response.status) {
        return response
            .text()
            .then(async (message) => {
                if ([JWT_EXPIRED, JWT_USER_UNKNOWN].includes(message)) {
                    localStorage.removeItem(LS_AUTH_TOKEN_KEY);
                    localStorage.removeItem("user");

                    //Here we try to identify what code online the user is trying to access to
                    //By using regex on url path
                    const codeOnlineRegexp = /[A-Z]{6}/;
                    let codeOnline = (window.location.href as any).match(
                        codeOnlineRegexp
                    )[0];

                    if (codeOnline) {
                        window.location.href = `/login/${codeOnline}`;
                    } else {
                        window.location.href = "/";
                    }

                    //Prevent next code to be executed till new location is loaded
                    await new Promise((r) => setTimeout(r, 15 * 1000));
                } else {
                    return response;
                }
            })
            .catch(() => {
                return response;
            });
    }
    return response;
};

type RootPath = "api" | "logout" | "login" | "form";

type PathApi = `/${RootPath}${string}`;

/**
 * Will explain the getURL because we need to get the subdomain sometime
 * TO BE EXPLAINED NIGER MAN
 */
const fetchApi = (path: PathApi, params?: RequestInit): Promise<Response> => {
    const Authorization = localStorage.getItem(LS_AUTH_TOKEN_KEY)
        ? `Bearer ${localStorage.getItem(LS_AUTH_TOKEN_KEY)}`
        : "";

    return fetch(`${getUrl()}${path}`, {
        headers: {
            "Content-Type": "application/json",
            Authorization,
            "X-Online-Spa": "spa",
            ...params?.headers,
        },
        ...omit(params, "headers"),
    });
};

export const checkCodeExists = (sessionCode: string) =>
    fetchApi(`/api/check-code/${sessionCode}`);

export const getLogInInformation = (sessionCode: string) =>
    fetchApi(`/api/session/${sessionCode}/connection-information`);

export const getSessionInformation = (
    sessionCode: string,
    args?: Array<string>
) =>
    fetchApi(
        `/api/session/${sessionCode}${
            args && args.length > 0 ? "?additionalFields=" + args.join(",") : ""
        }`
    );

export const getCurrentUser = (args?: Array<string>) =>
    fetchApi(
        `/api/user/me${
            args && args.length > 0 ? "?additionalFields=" + args.join(",") : ""
        }`
    );

export const loginFormScenario = (
    sessionCode: string,
    action: string,
    data: any
) =>
    fetchApi(`${action as PathApi}`, {
        method: "POST",
        redirect: "manual",
        body: JSON.stringify({ codeOnline: sessionCode, ...data }),
    });

export const loginAnonymously = (sessionCode: string) =>
    fetchApi(`/login-anonymously/${sessionCode}`);

export const loginEmailPassword = ({
    email,
    password,
    codeOnline,
}: {
    email: string;
    password: string;
    codeOnline: string;
}) =>
    fetchApi(`/form/scenario/email_password`, {
        method: "POST",
        redirect: "manual",
        body: JSON.stringify({
            email,
            password,
            codeOnline,
        }),
    });

export const loginEmailPasswordSms = ({
    email,
    password,
    codeOnline,
    verificationCode,
}: {
    email: string;
    password: string;
    codeOnline: string;
    verificationCode: string;
}) =>
    fetchApi(`/form/scenario/email_password_sms`, {
        method: "POST",
        redirect: "manual",
        body: JSON.stringify({
            email,
            password,
            codeOnline,
            verificationCode,
        }),
    });

export const requestSmsVerificationCode = ({
    email,
    password,
    codeOnline,
}: {
    email: string;
    password: string;
    codeOnline: string;
}) =>
    fetchApi(`/api/request-verification-code/${codeOnline}`, {
        method: "POST",
        redirect: "manual",
        body: JSON.stringify({
            email,
            password,
        }),
    });

export const requestEmailPassword = ({
    email,
    codeOnline,
}: {
    email: string;
    codeOnline: string;
}) =>
    fetchApi(`/api/request-password/${codeOnline}`, {
        method: "POST",
        redirect: "manual",
        body: JSON.stringify({
            email,
        }),
    });

export const logOut = () =>
    fetchApi(`/logout`, {
        method: "POST",
    });

export const sendTagCloudToDisplay = (
    sessionCode: string,
    response: {
        words: string[];
        remoteNumber: string | undefined;
    }
) =>
    fetchApi(`/api/session/${sessionCode}/tag-cloud`, {
        method: "POST",
        body: JSON.stringify(response),
    });

export const performSignature = (params: { signature: string }) =>
    fetchApi(`/api/user/me/sign`, {
        method: "PUT",
        redirect: "manual",
        body: JSON.stringify(params),
    });

//Allow to find session from associated subdomain
export const reverseSessionSubdomain = () =>
    fetchApi(`/api/session/reverse-subdomain`, {
        method: "GET",
    });

export const getSubdomainFromSession = (sessionCode: string) =>
    fetchApi(`/api/session/${sessionCode}/subdomain`, {
        method: "GET",
    });

export const getCustomization = () =>
    fetchApi(`/api/session/customization`, {
        method: "GET",
    });
