export type EvaluationQuestion = {
    id: number;
    title: string;
};

export type QuestionType = {
    authorizeWeightDistribution: boolean;
    canAnswerBeUpdated: boolean;
    id: number;
    isSecret: boolean;
    maxNumberOfAnswers: number;
    minNumberOfAnswers: number;
    numberOfSeats: number;
    multiSubmit: boolean;
    title: string;
    subTitles: Array<string>;
    evaluationQuestions: Array<EvaluationQuestion>;
    type: string;
    allowDuplicate: boolean;
    minValue: number;
    maxValue: number;
    useDecimal: boolean;
    evaluationDisplayType: EvaluationDisplayType;
    propositions: Array<{
        disableOtherPropositionsIfSelected: boolean;
        id: string;
        text: string;
        shortText: string;
        isVisibleByDefault: boolean;
    }>;
};

export type DataVoteType = {
    isReopened: boolean;
    questions: Array<QuestionType>;
    responseFormatVersion: number;
    voteTitle: string;
};

export enum EvaluationDisplayType {
    NUMBER = "number",
    STARS = "stars",
    HEARTS = "hearts",
    SMILES = "smiles",
}
