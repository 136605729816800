import { useState, FormEvent, ChangeEvent } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { VoteStatus } from "types/voteEnum";
import { QuestionType } from "types/dataVoteType";

import ActionSection from "./ActionSection";
import TitleHeader from "./TitleHeader";

import { getSocket } from "components/socket";
import { EVENT } from "types/socketEventsType";

type Props = {
    question: QuestionType;
    numTelecoEncrypted: string;
    responseFormatVersion: number;
};

const emitAsync = (args: {
    votingDeviceId: string;
    responseFormatVersion: number;
    answers: { questionId: number; answer: string }[]; //no weight needed for roll-call/tests question type
    //generic method makes answering broken
    //Thus, i duplicate it here for our needs
}) => {
    return new Promise<{ [key: string]: any }>((resolve, reject) => {
        getSocket().emit(EVENT.ANSWER, args, (res: any) => {
            if ("SUCCESS" === res.status) {
                resolve(res.data);
            } else {
                reject({ code: res.code, message: res.message });
            }
        });
    });
};

const NumericQuestion = ({
    question,
    numTelecoEncrypted,
    responseFormatVersion,
}: Props) => {
    const [answer, setAnswer] = useState<string>("");
    const [voteStatus, setVoteStatus] = useState<string>(VoteStatus.Idle);

    const { t } = useTranslation();

    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (answer.length >= 1) {
            handleValidate();
        }
    };

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        const { value } = e.currentTarget;
        setAnswer(value);
        setVoteStatus(VoteStatus.PendingValidation);
        if (0 === value?.length) {
            setVoteStatus(VoteStatus.Idle);
        }
    };

    const handleValidate = () => {
        setVoteStatus(VoteStatus.Loading);

        onValidate(answer)
            .then(() => {
                setVoteStatus(VoteStatus.Success);
            })
            .catch(() => {
                setVoteStatus(VoteStatus.Error);
            });
    };

    const handleReset = () => {
        setAnswer("");
        setVoteStatus(VoteStatus.Idle);
    };
    const handlePressEnter = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key !== "Enter") {
            return;
        }

        e.preventDefault();

        const answerIsNumber = !Number.isNaN(Number.parseFloat(answer));
        if (answerIsNumber) {
            handleSubmit(e as any);
        }
    };

    const onValidate = (answer: string) =>
        emitAsync({
            votingDeviceId: numTelecoEncrypted as string,
            responseFormatVersion: responseFormatVersion as number,
            answers: [
                {
                    questionId: question?.id as number,
                    answer,
                } as any,
            ],
        });

    return (
        <WrapperVote>
            <TitleHeader title={question.title} />
            <WrapperForm>
                <form onSubmit={handleSubmit} autoComplete="off">
                    <StyledInput
                        value={answer}
                        onChange={handleChange}
                        onKeyDown={handlePressEnter}
                        type={"number"}
                        maxLength={30}
                        placeholder={t("enterNumber")}
                    />
                </form>
            </WrapperForm>
            <ActionSection
                voteStatus={voteStatus}
                handleValidate={handleValidate}
                handleReset={handleReset}
            />
        </WrapperVote>
    );
};

const WrapperVote = styled.div`
    width: 100%;
    max-width: 620px;
    margin: auto;
    @media (max-width: 768px) {
        max-width: 350px;
    }
`;

const WrapperForm = styled.div`
    text-align: center;
    margin-top: 1.5rem;
`;

const StyledInput = styled.input`
    margin: auto;
    height: 35px;
    padding: 6px 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 15px;
    width: 100%;
    box-sizing: border-box;
    &[disabled] {
        background: #e8e8e8;
        opacity: 0.7;
        cursor: not-allowed;
    }
    :focus-visible {
        outline: solid 1px #010203;
    }
`;

export default NumericQuestion;
