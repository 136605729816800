import styled from "styled-components";
import { useTranslation } from "react-i18next";

import { VoteStatus } from "../Deliberation/useVoteState";
import ValidateButton from "../common/Buttons/ValidateButton";

type Props = {
    voteStatus: VoteStatus;
    remainingWeight?: number;
    validateVote: () => void;
    resetVote: () => void;
};

const ActionSection = ({
    voteStatus,
    validateVote,
    resetVote,
    remainingWeight = 0,
}: Props) => {
    const i18n = useTranslation();

    const renderInformationMessage = () => {
        switch (voteStatus) {
            case VoteStatus.Incomplete:
                return (
                    <p>
                        {i18n.t(
                            `errorVoteIncomplete.${process.env.REACT_APP_THEME}`
                        )}
                    </p>
                );
            case VoteStatus.Success:
                return (
                    <p>
                        {i18n.t(`sentSucceded.${process.env.REACT_APP_THEME}`)}
                    </p>
                );
            case VoteStatus.Loading:
                return <p>...{i18n.t("loading")}</p>;
            case VoteStatus.PendingValidation:
                return (
                    <p>
                        {i18n.t(
                            `answerNotValidated.${process.env.REACT_APP_THEME}`
                        )}
                    </p>
                );
            case VoteStatus.Error:
                return <p>{i18n.t("errorUnknown")}</p>;
        }
    };

    return (
        <Wrapper>
            {VoteStatus.Idle === voteStatus ? (
                <div style={{ height: "30px" }}></div>
            ) : (
                <StyledMessage
                    data-test-id="message-distribution"
                    color={
                        VoteStatus.Success === voteStatus ? "#60b860" : "red"
                    }
                >
                    <div>{renderInformationMessage()}</div>
                </StyledMessage>
            )}
            <ValidateButton
                isDisabled={
                    VoteStatus.Idle === voteStatus ||
                    VoteStatus.Incomplete === voteStatus ||
                    VoteStatus.NotAllowed === voteStatus ||
                    VoteStatus.Loading === voteStatus ||
                    remainingWeight !== 0
                }
                onClick={
                    VoteStatus.Success === voteStatus ? resetVote : validateVote
                }
                text={
                    VoteStatus.Success === voteStatus
                        ? i18n.t(`changeAnswer.${process.env.REACT_APP_THEME}`)
                        : i18n.t(
                              `validateAnswer.${process.env.REACT_APP_THEME}`
                          )
                }
            />
        </Wrapper>
    );
};

const Wrapper = styled.div`
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 14px;
    margin-top: 1.75rem;
    margin-bottom: 1.75rem;
`;

const StyledMessage = styled.div`
    color: ${(props) => props.color};
    width: 100%;
    text-align: center;
`;

export default ActionSection;
