import React from "react";
import styled from "styled-components";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";

import { checkCodeExists } from "components/helper/callApi";
import { getCustomizationLocalStorageWithExpiry } from "services/customizationService";
import { CSS_VARIABLES } from "enums/cssVariables";
import { ERROR } from "types/snackBar.constant";

import Layout from "./common/Layout";
import { ValidateButton } from "./common/ValidateButton";
import { Input } from "./common/Input";

type Inputs = {
    sessionCode: string;
};

const HomePage = () => {
    const {
        register,
        handleSubmit,
        setValue,
        setError,
        resetField,
        formState: { errors },
    } = useForm<Inputs>({
        mode: "onTouched",
    });

    const i18n = useTranslation();
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();

    const isCustomization = Boolean(getCustomizationLocalStorageWithExpiry());

    const onSubmit = (inputs: Inputs) => {
        checkCodeExists(inputs.sessionCode)
            .then((res) => {
                if (res.ok) {
                    resetField("sessionCode");
                    navigate(`/login/${inputs.sessionCode}`);
                } else {
                    enqueueSnackbar(
                        i18n.t(`errorCode.${process.env.REACT_APP_THEME}`),
                        {
                            variant: ERROR,
                        }
                    );
                    setError("sessionCode", {
                        type: "manual",
                    });
                }
            })
            .catch((e) => {
                enqueueSnackbar(`${i18n.t("errorUnknown")}`, {
                    variant: ERROR,
                });
            });
    };

    return (
        <>
            <Layout>
                <Wrapper>
                    <FormWrapper>
                        <FormTitle isCustomization={isCustomization}>{`${i18n.t(
                            "welcome"
                        )}`}</FormTitle>
                        <FormContent>
                            <p>
                                {i18n.t(
                                    `enterCode.${process.env.REACT_APP_THEME}`
                                )}
                            </p>
                            <div>
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <StyledInput
                                        {...register("sessionCode")}
                                        error={Boolean(errors.sessionCode)}
                                        placeholder={i18n.t(
                                            `codeOnline.${process.env.REACT_APP_THEME}`
                                        )}
                                        aria-invalid={
                                            errors.sessionCode
                                                ? "true"
                                                : "false"
                                        }
                                        onChange={(
                                            e: React.FormEvent<HTMLInputElement>
                                        ) => {
                                            const value =
                                                e.currentTarget.value || "";
                                            setValue(
                                                "sessionCode",
                                                value.toUpperCase()
                                            );
                                        }}
                                    />
                                    <StyledButton type="submit">
                                        {`${i18n.t("joinIn")}`}
                                    </StyledButton>
                                </form>
                            </div>
                        </FormContent>
                    </FormWrapper>
                </Wrapper>
            </Layout>
        </>
    );
};

interface StyledIsCustomizationProps {
    isCustomization: boolean;
}

const Wrapper = styled.div`
    padding: 0 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const FormWrapper = styled.div`
    text-align: center;
    background-color: #fafafa;
    box-shadow: 0px 0px 3px 0px rgb(0 0 0 / 35%);
    width: 320px;
`;

const FormTitle = styled.div<StyledIsCustomizationProps>`
    font-size: 1.3rem;
    padding: 1.25rem 1rem;
    color: ${(props) =>
        props.isCustomization
            ? `var(${CSS_VARIABLES.BACKGROUND_FORM_COLOR})`
            : `var(${CSS_VARIABLES.PRIMARY_TEXT_COLOR})`};
`;

const FormContent = styled.div`
    padding: 0 1.5rem 0.5rem 1.5rem;
    > p {
        font-size: 0.9rem;
        line-height: 1.5rem;
        margin: 0;
        padding-bottom: 1rem;
    }
    > div {
        margin-bottom: 15px;
    }
`;

const StyledInput = styled(Input)<{ error: boolean }>`
    border-color: ${(props) => (props.error ? "#dc3545" : null)};
    box-shadow: ${(props) =>
        props.error ? "0 0 0 0.2rem rgb(220 53 69 / 25%)" : null};
    &:focus {
        border-color: ${(props) => (props.error ? "#dc3545" : null)};
        box-shadow: ${(props) =>
            props.error ? "0 0 0 0.2rem rgb(220 53 69 / 25%)" : null};
    }
`;

const StyledButton = styled(ValidateButton)``;

export default HomePage;
