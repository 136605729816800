import { Navigate, useParams } from "react-router-dom";

import { useUser } from "components/helper/userContext";
import { useSession } from "components/helper/sessionContext";

import SessionPage from "components/SessionPage";
import Signature from "components/authentificationMode/Signature";

const SessionPageGuard = () => {
    const { onlineCode } = useParams();
    const { user } = useUser();
    const { session } = useSession();

    const matchingCodeOnline = onlineCode?.match(/[A-Z]{6}/);
    if (!matchingCodeOnline?.length) {
        return <Navigate replace to={`/`} />;
    }

    if (!user || !session) {
        return <Navigate replace to={`/login/${onlineCode}`} />;
    }

    if (session.isSignatureRequired && !user.isSigned) {
        return <Signature />;
    }

    return <SessionPage></SessionPage>;
};
export default SessionPageGuard;
