import { EvaluationProps, RatingWrapper } from "./GroupedEvaluation";
import Rating from "@mui/material/Rating";

const StarsEvaluation = (props: EvaluationProps) => {
    return (
        <RatingWrapper>
            <p>{props.subTitle}</p>
            <Rating
                value={Number(props.answers[props.index]?.answer)}
                max={props.maxValue}
                precision={props.useDecimal ? 0.5 : 1}
                onChange={(e, newValue) =>
                    props.onRateChange(e, newValue, props.id, props.index)
                }
            />
        </RatingWrapper>
    );
};

export default StarsEvaluation;
