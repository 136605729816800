import React, { useState, useContext, createContext } from "react";

type SubdomainType = {
    IsSubdomainHandlingCompleted: boolean;
    setSubdomainHandlingCompleted: React.Dispatch<
        React.SetStateAction<boolean>
    >;
};

type SubdomainProviderProps = {
    children: React.ReactNode;
};

const Subdomain = createContext<SubdomainType>({
    IsSubdomainHandlingCompleted: false,
    setSubdomainHandlingCompleted: () => {},
});

export const useSubdomain = () => useContext(Subdomain);

export const SubdomainProvider = ({ children }: SubdomainProviderProps) => {
    const [IsSubdomainHandlingCompleted, setSubdomainHandlingCompleted] =
        useState<boolean>(false);

    return (
        <Subdomain.Provider
            value={{
                IsSubdomainHandlingCompleted,
                setSubdomainHandlingCompleted,
            }}
        >
            {children}
        </Subdomain.Provider>
    );
};
