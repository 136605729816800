import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import { HTTPResponseError } from "services/errors";
import { useUser } from "components/helper/userContext";
import {
    getCurrentUser,
    getSessionInformation,
    loginEmailPassword,
} from "components/helper/callApi";

import EmailForm from "./common/EmailForm";
import EmailPasswordForm from "./common/EmailPasswordForm";
import { LS_AUTH_TOKEN_KEY } from "constants/constants";
import { useNavigate } from "react-router-dom";
import { SessionType } from "types/sessionType";
import { useSession } from "components/helper/sessionContext";

type Props = {
    codeOnline: string;
    handleSubmit?: (email: string, password: string) => Promise<void>;
    isSignatureRequired: boolean;
};

const LoginPassword = ({
    codeOnline,
    handleSubmit: handleSubmitBase,
    isSignatureRequired,
}: Props) => {
    const i18n = useTranslation();
    const navigate = useNavigate();

    const userContext = useUser();
    const { setSession } = useSession();

    const handleSubmit =
        handleSubmitBase !== undefined
            ? handleSubmitBase
            : async (email: string, password: string) => {
                  let loginResponse;
                  let currentUserResponse;

                  try {
                      loginResponse = await loginEmailPassword({
                          email,
                          password,
                          codeOnline,
                      });

                      if (loginResponse.status !== 200) {
                          throw new HTTPResponseError(loginResponse);
                      }

                      const response = await loginResponse.json();

                      if (!response.token) {
                          throw new Error();
                      }

                      localStorage.setItem(LS_AUTH_TOKEN_KEY, response.token);

                      currentUserResponse = await getCurrentUser(
                          //get Proxies if signature is required, to display
                          isSignatureRequired ? ["proxies", "isSigned"] : []
                      );
                      if (currentUserResponse.status !== 200) {
                          throw new HTTPResponseError(currentUserResponse);
                      }
                  } catch (error) {
                      if (!(error instanceof HTTPResponseError)) {
                          throw i18n.t("errorUnknown");
                      }

                      localStorage.removeItem(LS_AUTH_TOKEN_KEY);

                      switch (error.response.status) {
                          case 401:
                              throw i18n.t("errorCredentialsIncorrect");
                          default:
                              throw i18n.t("errorUnknown");
                      }
                  }

                  const userData = await currentUserResponse.json();
                  userContext.setUser(userData);

                  // Get session information
                  let sessionResponse;

                  try {
                      sessionResponse = await getSessionInformation(
                          codeOnline,
                          ["zoomSignature"]
                      );
                      if (sessionResponse.status !== 200) {
                          throw new HTTPResponseError(currentUserResponse);
                      }
                  } catch (error) {
                      if (!(error instanceof HTTPResponseError)) {
                          throw i18n.t("errorUnknown");
                      }
                      switch (error.response.status) {
                          case 401:
                              throw i18n.t("errorYouMustBeLog");
                          case 403:
                              throw i18n.t("error403");
                          default:
                              throw i18n.t("errorUnknown");
                      }
                  }

                  const sessionData: SessionType = await sessionResponse.json();
                  setSession(sessionData);

                  navigate(`/session/${codeOnline}`, {
                      replace: true,
                  });
              };

    const [hasPasswordRequestSucceeded, setHasPasswordRequestSucceeded] =
        useState(false);
    const [email, setEmail] = useState<string | null>(null);
    const [expanded, setExpanded] = useState<string | false>(false);

    const handleChange =
        (panel: string) =>
        (event: React.SyntheticEvent, newExpanded: boolean) => {
            setExpanded(newExpanded ? panel : false);
        };

    return (
        <>
            <Accordion
                disableGutters={true}
                expanded={expanded === "panel1"}
                onChange={handleChange("panel1")}
            >
                <AccordionHeader>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <div>{`${i18n.t("youHaveCredentials")}`}</div>
                    </AccordionSummary>
                </AccordionHeader>

                <AccordionDetails>
                    <EmailPasswordForm
                        successMessage={
                            hasPasswordRequestSucceeded
                                ? i18n.t("passwordEmailSent")
                                : undefined
                        }
                        defaultEmailValue={email ?? undefined}
                        handleSubmit={handleSubmit}
                    />
                </AccordionDetails>
            </Accordion>

            <Accordion
                disableGutters={true}
                expanded={expanded === "panel2"}
                onChange={handleChange("panel2")}
            >
                <AccordionHeader>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <div>{`${i18n.t("youDontHaveCredentials")}`}</div>
                    </AccordionSummary>
                </AccordionHeader>

                <AccordionDetails>
                    <EmailForm
                        onlineCode={codeOnline}
                        onSubmitSuccess={(emailValue) => {
                            setHasPasswordRequestSucceeded(true);
                            setEmail(emailValue);
                            setExpanded("panel1");
                        }}
                        onSubmitFail={() => {
                            setHasPasswordRequestSucceeded(false);
                            setEmail(null);
                        }}
                    />
                </AccordionDetails>
            </Accordion>
        </>
    );
};

const AccordionHeader = styled.div`
    display: flex;
    flex-direction: row;
    background-color: #f5f5f5;
    padding: 0;
    min-height: 50px;
    align-items: center;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    cursor: pointer;
    border-bottom: 0;
    > div {
        text-align: left;
        font-size: 14px;
    }
    > i {
        padding: 0.75rem;
    }
`;

export default LoginPassword;
