import { useTranslation } from "react-i18next";
import styled from "styled-components";

type Props = {
    displayName: string;
};

export default function NoVoteAllowedMessage({ displayName }: Props) {
    const i18n = useTranslation();

    return (
        <NoVoteAllowed data-test-id={`voter-${displayName}`}>
            {i18n.t("noAllowedToVote")}
        </NoVoteAllowed>
    );
}

const NoVoteAllowed = styled.p`
    color: #f62323;
    font-weight: 600;
    margin-top: 10px;
`;
